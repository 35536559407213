import '../index.css';
import React from "react";
import * as d3 from "d3";

function Question3() {
    const bar1 = React.useRef();
    const bar2 = React.useRef();

    const [nowText1, setNowText1] = React.useState("");
    const [nowText2, setNowText2] = React.useState("");
    const [nowText3, setNowText3] = React.useState("");
    // 最后一题
    const [nowText4, setNowText4] = React.useState("");

    // 需要传递的对象
    const [msg,setMsg] = React.useState({
        content: '',
        questionId: '143',
        nodeId: '01',
        type: 'TEXT',
    });

    // 根据选择的不同改变msg的值
    function changeSelection(e) {
        let message = JSON.stringify({
            choice: e.target.value
        })
        let data = Object.assign({}, msg, { content : message ,nodeId: '04' ,type:'SELECTION'})
        console.log(data);
        setMsg(data)
    }

    // 根据输入内容的不同改变msg的值
    function changeTextArea(str,e) {
        let data
        if(str == '最小值'){
            let message = JSON.stringify({
                beforeText:  nowText1,
                afterText: e.target.value,
            })
            setNowText1(e.target.value)
            data = Object.assign({}, msg, { content : message ,nodeId: '01' ,type:'TEXT_BOX_INPUT'})
        }else if(str == '最大值'){
            let message = JSON.stringify({
                beforeText:  nowText2,
                afterText: e.target.value,
            })
            setNowText2(e.target.value)
            data = Object.assign({}, msg, { content : message ,nodeId: '02' ,type:'TEXT_BOX_INPUT'})
        }else if(str == '期望值'){
            let message = JSON.stringify({
                beforeText:  nowText3,
                afterText: e.target.value,
            })
            setNowText3(e.target.value)
            data = Object.assign({}, msg, { content : message ,nodeId: '03' ,type:'TEXT_BOX_INPUT'})
        }
        console.log(data);
        setMsg(data)
    }

    function changeTextArea2(e) {
        let message = JSON.stringify({
            beforeText:  nowText4,
            afterText: e.target.value,
        })
        setNowText4(e.target.value)
        let data = Object.assign({}, msg, { content : message ,nodeId: '05' ,type : 'TEXT_BOX_INPUT'})
        console.log(data);
        setMsg(data)
    }

    // 更新即向iframe父组件传递msg
    React.useEffect(()=>{
        console.log("问题3开始向父组件传msg...");
        top.postMessage(msg,'http://121.4.138.132:8888/#/epidemiology')
        // top.postMessage(msg,'http://localhost:8081/#/epidemiology')
    },[msg])
    
    React.useEffect(() => {

        const dataset1 =  [
            {key: "健康", value: 0.58, color: "#66cc33"},
            {key: "潜伏期", value:0.38, color: "#0099cc"},
            {key: "感染", value:0.04, color: "#ff6600"},
        ];

        const dataset2 =  [
            {key: "健康", value: 0.35, color: "#66cc33"},
            {key: "潜伏期", value:0.16, color: "#0099cc"},
            {key: "感染", value:0.49, color: "#ff6600"},
        ];



        const margin = {top: 30, right: 30, bottom: 70, left: 60},
            width = 300 - margin.left - margin.right,
            height = 360 - margin.top - margin.bottom;
        const svg1 = d3.select(bar1.current)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")")
            .attr("class", "bar1");
        const svg2 = d3.select(bar2.current)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")")
            .attr("class", "bar1");
        const x = d3.scaleBand()
            .range([ 0, width ])
            .domain(["健康", "潜伏期", "感染"])
            .padding(0.3);
        svg1.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x))
            .attr("font-size","15px");
        svg2.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x))
            .attr("font-size","15px");

        const y = d3.scaleLinear()
            .domain([0, 0.6])
            .range([ height, 0]);
        svg1.append("g")
            .call(d3.axisLeft(y));
        svg2.append("g")
            .call(d3.axisLeft(y));

        svg1.selectAll(".bar1")
            .data(dataset1)
            .enter()
            .append("rect")
            .attr("x", d => x(d.key))
            .attr("y", d => y(d.value))
            .attr("width", x.bandwidth())
            .attr("height", d => height - y(d.value))
            .attr("fill", d => d.color);

        svg2.selectAll(".bar2")
            .data(dataset2)
            .enter()
            .append("rect")
            .attr("x", d => x(d.key))
            .attr("y", d => y(d.value))
            .attr("width", x.bandwidth())
            .attr("height", d => height - y(d.value))
            .attr("fill", d => d.color);

        svg1.selectAll(".bar1")
            .data(dataset1)
            .enter()
            .append("text")
            .text(d => d.value)
            .attr("x", function(d, i) {
                return i * (width / 3.3) + (width / 3 - 0.1 * width) / 2;
            })
            .attr("y", function(d) {
                return height - (d.value * 445);
            });

        svg2.selectAll(".bar1")
            .data(dataset2)
            .enter()
            .append("text")
            .text(d => d.value)
            .attr("x", function(d, i) {
                return i * (width / 3.3) + (width / 3 - 0.1 * width) / 2;
            })
            .attr("y", function(d) {
                return height - (d.value * 445);
            });


    }, []);

    return (
        <div>
            {/* 题目标题 */}
            <div className="itemTitle">
                <b>第4题 第3问</b>
            </div>

            <div className='bgContext'>阅读材料，完成下列要求。</div>

            <div className="border-2 px-28 py-5 mx-auto text-base">
                <p className="mx-2 mt-2 mb-4 indent-8">
                    为了降本增效，目前常用的是核酸检测方式是<span className="font-bold">混采</span>，即在采集时将多人样本放在一个采集管内，以备后续检测。
                    混采初筛发现阳性后，当地防疫部门将安排混检人员落实就地管控措施，同时安排上门<span className="font-bold">单采</span>开展复核。
                    单采，即每个采集管作为一个独立的样本进行检测，人均成本高于混采。
                </p>

                <p className="mx-2 mt-2 mb-4 indent-8">
                    假设采用10合1混采，混采成本为30元/管，单采成本为20元/管。
                    柱状图表示100人的群体里健康、潜伏期和感染各自所占比例，若要检测出所有感染者，请计算成本可能的最小值、最大值和期望值。
                </p>

                <div className="flex justify-evenly items-center">
                    <svg className="mb-auto mt-2" ref={bar1}> </svg>
                    <div>
                        <div className="form-control">
                            <label className="input-group mb-4">
                                <span>最小值</span>
                                <input type="text" placeholder="单位：元" className="input input-bordered" onBlur={(e)=> changeTextArea( "最小值",e )}/>
                            </label>
                            <label className="input-group mb-4">
                                <span>最大值</span>
                                <input type="text" placeholder="单位：元" className="input input-bordered" onBlur={(e)=> changeTextArea( "最大值",e )}/>
                            </label>
                            <label className="input-group">
                                <span>期望值</span>
                                <input type="text" placeholder="单位：元" className="input input-bordered" onBlur={(e)=> changeTextArea( "期望值",e )}/>
                            </label>
                        </div>
                    </div>
                </div>

                <p className="mx-2 mt-2 mb-4 indent-8">
                    若人群变为如下比例，则核酸成本将如何变化，请说明理由。
                </p>

                <div className="flex justify-start items-center">
                    <svg className="mb-auto mt-2 ml-14" ref={bar2}> </svg>
                    <div className="ml-16">
                        <div className="form-control">
                            <label className="label cursor-pointer">
                                <span className="label-text mr-2">成本增高</span>
                                <input type="radio" name="radio-6" className="radio checked:bg-blue-500" value="成本增高" onChange={changeSelection}/>
                            </label>
                        </div>
                        <div className="form-control">
                            <label className="label cursor-pointer">
                                <span className="label-text mr-2">成本降低</span>
                                <input type="radio" name="radio-6" className="radio checked:bg-blue-500" value="成本降低" onChange={changeSelection} />
                            </label>
                        </div>
                        <div className="form-control">
                            <label className="label cursor-pointer">
                                <span className="label-text mr-2">成本保持不变</span>
                                <input type="radio" name="radio-6" className="radio checked:bg-blue-500" value="成本保持不变" onChange={changeSelection}/>
                            </label>
                        </div>
                    </div>

                </div>
                <div>
                    <textarea id="reason2" className="textarea textarea-bordered w-full" placeholder="请输入您的理由" onBlur={(e)=> changeTextArea2(e)}></textarea>
                </div>
            </div>

        </div>
    )
}

export default Question3;
