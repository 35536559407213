import React from "react";
import '../index.css';
import CheckboxGroup from 'react-checkbox-group';

function Question1() {
    // 选择题的value
    const [checkbox, setCheckbox] = React.useState([]); 
    const [checkbox2, setCheckbox2] = React.useState([]); 

    // 需要传递的对象
    const [msg,setMsg] = React.useState({
        content: '',
        questionId: '145',
        nodeId: '01',
        type: 'OPTION',
    });

    // 根据选择的不同改变msg的值
    function changeSelection(e) {
        let data = Object.assign({}, msg, { content : e.target.value })
        setMsg(data)
    }

    // 更新即向iframe父组件传递msg
    React.useEffect(()=>{
        let message = JSON.stringify({
            choice: checkbox
        })
        let data = Object.assign({}, msg, { content : message ,nodeId: '01', type: 'SELECTION'})
        setMsg(data)
    },[checkbox])

    React.useEffect(()=>{
        let message = JSON.stringify({
            choice: checkbox2
        })
        let data = Object.assign({}, msg, { content : message ,nodeId: '02', type: 'SELECTION'})
        setMsg(data)
    },[checkbox2])

    React.useEffect(()=>{
        console.log("问题5开始向父组件传msg...");
        top.postMessage(msg,'http://121.4.138.132:8888/#/epidemiology')
        // top.postMessage(msg,'http://localhost:8081/#/biologic_question_2')
    },[msg])

    return (
        <div>
            {/* 题目标题 */}
            <div className="itemTitle">
                <b>第4题 第5问</b>
            </div>

            {/* 问题区域 */}
            <div className='itemStem'>
                <div className='bgContext'>选择一个或多个选项以回答问题。</div>
                <div className='bgContext'>模拟是探究疾病对人群影响和研究相关防疫措施的有效手段。</div>
                <div className='bgContext'>请你通过模拟判断以下表述是否正确，并选中你认为的正确项。<b style={{color: 'red'}}> &emsp;（多选题）</b></div>
            </div>

            <fieldset className="p-2">
            <CheckboxGroup
            checkboxDepth={2}
            name="checkbox"
            value={checkbox}
            onChange={(items) => { setCheckbox(items) }}
            >
                {(Checkbox) => (
                    <>
                    <label>
                        <Checkbox value="A" /> 若要减少单采人次，可以选择降低单管混采人数
                    </label>
                    <br></br>
                    <label>
                        <Checkbox value="B" /> 疫情不严重时，选择较大的单管混采人数降低成本
                    </label>
                    <br></br>
                    <label>
                        <Checkbox value="C" /> 单管混采人数的选择会显著影响感染者比例
                    </label>
                    </>
                )}
            </CheckboxGroup>
            </fieldset>

            <div className='bgContext'>你认为利用模拟有哪些好处？<b style={{color: 'red'}}>（多选题）</b></div>

            <fieldset className="p-2">
            <CheckboxGroup
            checkboxDepth={2}
            name="checkbox"
            value={checkbox2}
            onChange={(items) => { setCheckbox2(items); }}
            >
                {(Checkbox) => (
                    <>
                    <label>
                        <Checkbox value="A" /> 可以帮助研发疾病的特效药
                    </label>
                    <br></br>
                    <label>
                        <Checkbox value="B" /> 可以预计疾病在各地区的发展状态
                    </label>
                    <br></br>
                    <label>
                        <Checkbox value="C" /> 可以估计免疫力提升后人群的感染比例
                    </label>   
                    <br></br>
                    <label>
                        <Checkbox value="D" /> 可以预测病毒未来是否会变异
                    </label>
                    </>
                )}
            </CheckboxGroup>
            </fieldset>
        </div>
    )
}

export default Question1;
