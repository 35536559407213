import '../index.css';
import React from "react";
import { useState } from 'react'
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useDrop, useDrag } from 'react-dnd';
import Iframe from "react-iframe";

function DropBox({onDrop, children}) {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ["box"],
        drop: onDrop,
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    })
    const isActive = isOver && canDrop
    return (
        <div ref={drop} data-testid="dustbin" className={"border-2 w-28 h-20 text-center text-base " + (isActive? "bg-blue-200" : "bg-white border-dashed")}>
            {children}
        </div>
    )
}

function DragBox({ text, children, index}) {
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: "box",
            item: { index } ,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [text],
    )

    if (isDragging) {
        return <div className="w-28 h-20" ref={drag} />
    }

    return (
        <div
            className="cursor-move border-2 w-28 h-20 text-center text-base"
            ref={drag}
            data-testid="box"
        >
            {children}
        </div>
    )
}

function Question4() {
    const [dropContent, setDropContent] = useState([3, 3, 3]);
    const [isDropped, setDropped] = useState([false, false, false]);

    // 需要传递的对象
    const [msg,setMsg] = React.useState({
        content: '',
        questionId: '144',
        nodeId: '04',
        type: 'TEXT',
    });

    // 根据选择的不同改变msg的值
    function changeSelection(str,e) {
        let message = JSON.stringify({
            choice: e.target.value
        })
        let data
        if(str == '核酸成本'){
            data = Object.assign({}, msg, { content : message ,nodeId: '02' ,type:'SELECTION'})
        }else if(str == '单管混采人数'){
            data = Object.assign({}, msg, { content : message ,nodeId: '03' ,type:'SELECTION'})
        }
        setMsg(data)
    }
    
    //更新三个框中的图片标记
    React.useEffect(()=>{
        let message = JSON.stringify({
            choice: dropContent
        })
        let data = Object.assign({}, msg, { content : message ,nodeId: '01' ,type:'SELECTION'})
        setMsg(data)
    },[dropContent])

    // 更新即向iframe父组件传递msg
    React.useEffect(()=>{
        console.log("问题4开始向父组件传msg...");
        top.postMessage(msg,'http://121.4.138.132:8888/#/biologic_question_9')
        // top.postMessage(msg,'http://localhost:8081/#/biologic_question_3')
    },[msg])

    function handleDrop (index, item) {
        if (dropContent[index] === 3) {
            const newDropContent = dropContent.slice();
            const newDropped = isDropped.slice();
            newDropContent[index] = item.index;
            newDropped[item.index] = true;
            setDropContent(newDropContent);
            setDropped(newDropped);
        }
    }

    function cancelDrop (index) {
        if (dropContent[index] !== 3) {
            const newDropContent = dropContent.slice();
            const newDropped = isDropped.slice();
            newDropContent[index] = 3;
            newDropped[dropContent[index]] = false;
            setDropContent(newDropContent);
            setDropped(newDropped);
        }
    }


    const options = [<>
        <img src={process.env.PUBLIC_URL + "/firstImg.png"} alt="latency"/>
    </>, <>
        <img src={process.env.PUBLIC_URL + "/secondImg.png"} alt="latency"/>
    </>, <>
        <img src={process.env.PUBLIC_URL + "/thirdImg.png"} alt="latency"/>
    </>, <></>];


    return (
        <div>
            {/* 题目标题 */}
            <div className="itemTitle">
                <b>第4题 第4问</b>
            </div>

            {/* 问题区域 */}
            <div className='itemStem'>
                <div className='bgContext'>根据以下信息运行模拟，收集数据。使用鼠标拖放，并从下拉菜单中进行选择回答问题。</div>
                <div className='bgContext'>混采的主要目的是减少成本。随着混采人数的提高，核酸成本如何变化？</div>
                <div className='bgContext'>请在核酸启动时间固定为40天时，探究成本随人数的变化情况。将以下三类人数设定按照成本比例从低到高的顺序排列。</div>
            </div>

            <DndProvider backend={HTML5Backend}>
                <div className="flex justify-evenly">
                    {isDropped[0]? <div className="w-28 h-20"></div> :<DragBox index={0}>{options[0]}</DragBox>}
                    {isDropped[1]? <div className="w-28 h-20"></div> :<DragBox index={1}>{options[1]}</DragBox>}
                    {isDropped[2]? <div className="w-28 h-20"></div> :<DragBox index={2}>{options[2]}</DragBox>}
                </div>
                <div className="text-center p-2 font-bold">
                    核酸检测的成本
                </div>
                <div className="flex justify-evenly">
                    <div onClick={() => cancelDrop(0)} className={dropContent[0] !== 3 ? "cursor-pointer" : ""}>
                        <DropBox onDrop={(item) => handleDrop(0, item)}>
                            {options[dropContent[0]]}
                        </DropBox>
                    </div>
                    <div onClick={() => cancelDrop(1)} className={dropContent[1] !== 3 ? "cursor-pointer" : ""}>
                        <DropBox onDrop={(item) => handleDrop(1, item)}>
                            {options[dropContent[1]]}
                        </DropBox>
                    </div>
                    <div onClick={() => cancelDrop(2)} className={dropContent[2] !== 3 ? "cursor-pointer" : ""}>
                        <DropBox onDrop={(item) => handleDrop(2, item)}>
                            {options[dropContent[2]]}
                        </DropBox>
                    </div>

                </div>
                <div className="flex justify-evenly">
                    <p>最低</p>
                    <p>------------></p>
                    <p>最高</p>
                </div>
            </DndProvider>

            <div className="mt-4 bg-base-200 mx-1 p-1 leading-8 indent-8">
                随着感染人数激增，若单管混采人数保持不变，核酸成本将会
                <select name="s1" id="s1-select" className="w-32 text-center" onChange={(e)=> changeSelection( "核酸成本",e )}>
                    <option value="">请选择</option>
                    <option value="上升">上升</option>
                    <option value="下降">下降</option>
                </select>
                ；若要使成本最低，应适当
                <select name="s2" id="s2-select" className="w-32 text-center" onChange={(e)=> changeSelection( "单管混采人数",e )}>
                    <option value="">请选择</option>
                    <option value="提高">提高</option>
                    <option value="降低">降低</option>
                </select>
                单管混采人数。
            </div>


        </div>
    )
}

export default Question4;

// class Home extends React.Component {
//   state = {
//     count: 0
//   }

//   gotoHome = () => {
//     this.props.history.go(-1)
//   }

//   function() {
//     console.log("haha");
//   }

//   componentDidMount() {
//     console.log("this is react componentDidMount.");
//     window.addEventListener('message',(e)=>{
//       console.log('e',e);
//     },false)
//   }

//   render() {
//     return (
//       <div>
//         <div style={{
//           width: '32px',
//           height: '32px',
//         }}></div>
//         <h3>this is home page.</h3>
//         <div>calcualtor:{this.state.count}</div>
//         <button onClick={() => {
//           this.setState({
//             count: this.state.count + 1
//           })
//         }}>+1</button>
//         {/* <button onClick={this.gotoHome}>return to login page.</button> */}
//       </div>
//     )
//   }
// }

// export default Home