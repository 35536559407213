import './index.css';

function Hero({ setPage }) {
    return (
        <div className="hero min-h-screen bg-base-200">
            <div className="hero-content text-center">
                <div className="max-w-xl mb-24">
                    <h1 className="text-5xl font-bold mb-12">欢迎体验多媒体动态题型</h1>
                    <button className="btn btn-primary" onClick={() => {setPage(1)}}>点击开始</button>
                </div>
            </div>
        </div>
    );
}

export default Hero;
