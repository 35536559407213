import React from 'react';
import '../index.css';
import {Slider} from "@mui/material";
import * as d3 from "d3";
import { useUpdateEffect } from 'ahooks';
import { useInterval } from 'ahooks';
import Solver from "./Solver";
import {gamma} from 'mathjs';


function combinations(k, n) {
    return gamma(k) / (gamma(n) * gamma(k-n));
}


function Canvas({cqid, gamma, betaI, betaE, epsilon, dt, maxT, N, hybrid, date, trigger, setRunning}) {
    const [index, setIndex] = React.useState(0);
    const [delay, setDelay] = React.useState(undefined);
    const [line, setLine] = React.useState([]);
    const [exp, setExp] = React.useState(0);
    const [table, setTable] = React.useState(Array(5).fill(Array(4).fill("")));
    const [table1, setTable1] = React.useState(Array(5).fill(Array(4).fill("")));


    const [msg,setMsg] = React.useState({
        content: '',
        questionId: '145',
        nodeId: '06',
        type: 'SELECTION',
    });

    React.useEffect(()=>{
        let message = {
            beforeRecords: table1,
            afterRecords: table
        }
        setTable1(table)
        let data
        if (cqid === 5){
            data = Object.assign({}, msg, { content : message , questionId : '144', nodeId: '06', type: 'SIMULATION_EXPERIMENT'})
        }else if (cqid === 6){
            data = Object.assign({}, msg, { content : message , questionId : '145', nodeId: '05', type: 'SIMULATION_EXPERIMENT'})                            
        }
        top.postMessage(data,'http://121.4.138.132:8888/#/epidemiology')
        // top.postMessage(msg,'http://localhost:8081/#/biologic_question_1')
    },[table])


    useInterval(() => {
        setIndex(index + 1);
    }, delay);

    function step(index) {
        if (index === 1) {
        }
        if (index === line.length) {
            setRunning(false);
            setDelay(undefined);
            setIndex(0);
            const newTable = table.slice();
            newTable[exp] = [date, hybrid,
                line.map(x => x[2] * dt).reduce((a, b) => a + b, 0).toFixed(2),
                line.map(x => x[3] * dt).reduce((a, b) => a + b, 0).toFixed(2)];
            setTable(newTable);
            setExp(exp + 1);
        }
        // else if (line[index][1][0] >= 99.5) {
        //     console.log("停止");
        //     setRunning(false);
        //     setDelay(undefined);
        //     setIndex(0);
        // }
        if (index !== 0) {
            d3.select(".lineI")
                .attr("fill", "none")
                .attr("stroke", "#ff6600")
                .attr("stroke-width", 4)
                .attr("d", d3.line()
                    .x(function (d) {
                        return x(d[0] - date)
                    })
                    .y(function (d) {
                        return y(d[1][2])
                    })(line.slice(0, index + 1))
                );

            d3.select(".lineCost")
                .attr("fill", "none")
                .attr("stroke", "#002aff")
                .attr("stroke-width", 4)
                .attr("d", d3.line()
                    .x(function (d) {
                        return x(d[0] - date)
                    })
                    .y(function (d) {
                        return yCost(d[2])
                    })(line.slice(0, index + 1))
                );

            d3.select(".lineQua")
                .attr("fill", "none")
                .attr("stroke", "#234814")
                .attr("stroke-width", 4)
                .attr("d", d3.line()
                    .x(function (d) {
                        return x(d[0] - date)
                    })
                    .y(function (d) {
                        return y(d[3])
                    })(line.slice(0, index + 1))
                );
        }
    }

    function clear() {
        d3.select(".break").attr('d', null);
        d3.select(".lineI").attr('d', null);
        d3.select(".lineCost").attr('d', null);
        d3.select(".lineQua").attr('d', null);
    }

    function render() {
        const LV1 = (t, y) => {
            return [
                -betaE * y[1] * y[0] / N - betaI * y[2] * y[0] / N + gamma * y[2],
                betaE * y[1] * y[0] / N + betaI * y[2] * y[0] / N - epsilon * y[1],
                epsilon * y[1] - gamma * y[2]
            ]
        }
        const LV2 = (t, y) => {
            return [
                -betaE * y[1] * y[0] / N + gamma * y[2],
                betaE * y[1] * y[0] / N - epsilon * y[1],
                epsilon * y[1] - gamma * y[2]
            ]
        }
        const res1 = (date !== 0) ? (new Solver(LV1, [99, 1, 0], 0, date)).euler(date / dt) : {ts: [], ys: []};
        const midY = (date !== 0) ? res1.ys[res1.ys.length-1] : [99, 1, 0];
        const midT = (date !== 0) ? res1.ts[res1.ts.length-1] : 0;
        const res2 = (new Solver(LV2, midY, midT, date + maxT)).euler(maxT / dt);
        setLine(res2.ts.map((k, i) => [
            k,
            res2.ys[i],
            (29 + hybrid * 0.1 + hybrid * 18 * (1 - combinations(N - res2.ys[i][2], hybrid) / combinations(N, hybrid))) * N / hybrid,
            (1 - combinations(N - res2.ys[i][2], hybrid) / combinations(N, hybrid)) * N,
        ]));
        setDelay(8);
    }


    const lineCanvas = React.useRef();

    const margin = {top: 10, right: 60, bottom: 30, left: 60},
        width = 592 - margin.left - margin.right,
        height = 280 - margin.top - margin.bottom;
    const x = d3.scaleLinear()
        .domain([0, maxT])
        .range([ 0, width ]);
    const y = d3.scaleLinear()
        .domain([0, N])
        .range([ height, 0 ]);
    const yCost = d3.scaleLinear()
        .domain([0, 2800])
        .range([ height, 0 ]);

    React.useEffect(() => {
        // console.log('init line canvas')
        const lineD3 = d3.select(lineCanvas.current)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");
        lineD3.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x));
        lineD3.append("g")
            .call(d3.axisLeft(y));
        lineD3.append("g")
            .attr("transform", "translate("+ width +",0)")
            .call(d3.axisRight(yCost));

        lineD3.append("path").attr("class", "break");
        lineD3.append("path").attr("class", "lineI");
        lineD3.append("path").attr("class", "lineCost");
        lineD3.append("path").attr("class", "lineQua");

    }, [])
    React.useEffect(clear,[date, hybrid]);
    useUpdateEffect(() => {step(index);}, [index]);
    useUpdateEffect(() => {render();}, [trigger]);

    return (
        <div>
            <svg className="bg-blue-100 mb-auto mt-2" ref={lineCanvas}>
                {/*<rect className="fill-transparent stroke-1 stroke-zinc-900" x="400" y="10" width="150" height="100" />*/}

                <circle style={{fill: "#ff6600"}} cx="450" cy="30" r="10" />
                <text x="465" y="35">感染</text>
                <circle style={{fill: "#002aff"}} cx="450" cy="55" r="10" />
                <text x="465" y="60">核酸成本</text>
                <circle style={{fill: "#234814"}} cx="450" cy="80" r="10" />
                <text x="465" y="85">单采人次</text>
                <text x="20" y="100" writingMode="tb-rl">人数</text>
                <text x="580" y="100" writingMode="tb-rl">成本</text>
                {/*<text x="300" y="277">天数</text>*/}

            </svg>
            <div className="overflow-x-auto mt-2 h-60">
                <table className="table table-compact w-full">
                    <thead>
                    <tr>
                        <th></th>
                        <th>启动时间</th>
                        <th>混采人数</th>
                        <th>核酸成本曲线面积</th>
                        <th>单采人次曲线面积</th>
                    </tr>
                    </thead>
                    <tbody>
                    {table.map((item, index) => (
                        <tr key={index}>
                            <th>{index + 1}</th>
                            <td>{item[0]}</td>
                            <td>{item[1]}</td>
                            <td>{item[2]}</td>
                            <td>{item[3]}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>

    )
}


function Stimulus1({qid}) {
    const [date, setDate] = React.useState(40);
    const [hybrid, setHybrid] = React.useState(10);
    const [running, setRunning] = React.useState(false);
    const [trigger, setTrigger] = React.useState(0);
    const maxT = 60;

    const [msg,setMsg] = React.useState({
        content: '',
        questionId: '141',
        nodeId: '01',
        type: 'SELECTION',
    });

    React.useEffect(()=>{
        console.log('usrEffect被触发');
        top.postMessage(msg,'http://121.4.138.132:8888/#/epidemiology')
        // top.postMessage(msg,'http://localhost:8081/#/biologic_question_1')
    },[msg])

    return (
        <div className="bg-gray-300 h-full p-2 flex flex-col">
            <div className="bg-white flex items-center justify-evenly h-16">
                <div className="font-bold text-center w-24">
                    每日全员核酸检测启动时间
                </div>
                <div className="w-80">
                    <Slider
                        aria-label="Small steps"
                        defaultValue={40}
                        getAriaValueText={value => value + "天"}
                        step={5}
                        marks={Array(maxT / 5 + 1).fill(0).map((x, y) => ({
                            value: x + y * 5,
                            label: (x + y * 5) % 10 === 0 ? x + y * 5 + '天' : "",
                        }))}
                        min={0}
                        max={maxT}
                        onChange={(e, v) => {
                            setDate(v);
                            let message = JSON.stringify({
                                position: v
                            })
                            let data
                            if (qid === 5){
                                data = Object.assign({}, msg, { content : message , questionId : '144', nodeId: '04', type: 'ONE_DIMENSIONAL_DRAGGING'})
                            }else if (qid === 6){
                                data = Object.assign({}, msg, { content : message , questionId : '145', nodeId: '03', type: 'ONE_DIMENSIONAL_DRAGGING'})                            
                            }
                            console.log(data);
                            setMsg(data)
                        }}
                        disabled={running}
                        valueLabelDisplay="auto"
                    />
                </div>
            </div>
            <div className="bg-white flex items-center justify-evenly h-16">
                <div className="font-bold text-center w-24">
                    单管混采人数
                </div>
                <div className="w-80">
                    <Slider
                        aria-label="Small steps"
                        defaultValue={10}
                        getAriaValueText={value => value + "人"}
                        step={1}
                        marks={Array(maxT + 1).fill(0).map((x, y) => ({
                            value: x + y,
                            label: (x + y) % 2 === 0 ? x + y + '人' : "",
                        }))}
                        min={4}
                        max={16}
                        onChange={(e, v) => {
                            setHybrid(v);
                            let message = JSON.stringify({
                                position: v
                            })
                            let data
                            if (qid === 5){
                                data = Object.assign({}, msg, { content : message , questionId : '144', nodeId: '05', type: 'ONE_DIMENSIONAL_DRAGGING'})
                            }else if (qid === 6){
                                data = Object.assign({}, msg, { content : message , questionId : '145', nodeId: '04', type: 'ONE_DIMENSIONAL_DRAGGING'})                            
                            }
                            console.log(data);
                            setMsg(data)
                        }}
                        disabled={running}
                        valueLabelDisplay="auto"
                    />
                </div>
            </div>
            <div className="bg-white w-full">
                <button
                    className={"w-12 font-bold rounded ml-72 mb-2 " + (running ? "bg-gray-200" : "bg-gradient-to-br from-blue-400 to-blue-200")}
                    disabled={running}
                    onClick={() => {
                        setRunning(true);
                        setTrigger(trigger+1);
                    }}>
                    运行
                </button>
            </div>

            <Canvas cqid={qid} gamma={2 / 20} betaI={6 / 20} betaE={3 / 20} epsilon={10 / 20}
                    dt={0.1} maxT={60} N={100} hybrid={hybrid} date={date} trigger={trigger} setRunning={setRunning}/>

        </div>
    )
}

export default Stimulus1;
