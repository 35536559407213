class Solver {
    constructor(ode, y0, t0, t1) {
        this.ode = ode
        this.y0 = y0
        this.t0 = t0
        this.t1 = t1

        if(isNaN(this.t0))
            console.warn("invalid starting time");
    }

    euler(resolution) {
        const h = (this.t1 - this.t0) / resolution
        const ts = Array.from(Array(resolution + 1), (_, k) => k * h + this.t0) //time series datapoints
        const ys = Array.from(Array(resolution + 1), () => Array(this.y0.length).fill(0))
        ys[0] = this.y0

        for (let i = 0; i < resolution; i++) {
            ys[i + 1] = this.ode(ts[i], ys[i]).map((x, j) => ys[i][j] + x * h)  //y_n+1 = y_n + dy/dx *h
        }
        return {
            ts: ts,
            ys: ys
        }
    }
}

export default Solver;
