function Background() {
    return (
        <div>
            {/* 题目标题 */}
            <div className="itemTitle">
                背景介绍
            </div>

            <div className='bgContext'>阅读背景介绍，然后点击"下一题"或"模拟"按钮。</div>

            <div className="border-2 px-40 py-10 mx-auto text-base">
                <p className="font-bold mb-8 text-center">核酸检测和疾病防控</p>
                <p className="mb-8 indent-8">
                    在世界很多地区，人们通过尽早发现和及时隔离感染者来阻断传染性疾病传播。
                    如新冠疫情下，开展核酸检测发现并隔离患病人群，被该疾病感染和传染他人的可能性会减少。
                </p>
                <p className="mb-8 indent-8">
                    下面这个模拟展示了某种非致死性传染疾病的在人群中传播时，检测核酸并隔离感染者对该疾病传播的影响。
                </p>
            </div>
        </div>
    )
}

export default Background;
