import './index.css';
import './nav.css'

function Navbar() {
    return (
        <div id="outer-box">
            <div style={{marginBottom: '12px'}}>
                <div id="naviger-box">
                    <div style={{
                        backgroundColor: 'rgba(216, 216, 216, 1)',
                        borderRadius: '50%',
                        height: '32px',
                        marginTop: '16px',
                        width: '32px'
                    }}>
                        <div style={{
                            width: '32px',
                            height: '32px',
                            background: 'url(' + process.env.PUBLIC_URL +'/smLogo.png) 100% no-repeat'
                        }}>
                            <div style={{width:'30px',  height:'30px'}}></div>
                        </div>
                    </div>
                    <span className="icon-text">教育部教育考试院</span>
                </div>
            </div>
        </div>


            );
}

export default Navbar;
