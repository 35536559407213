import React from 'react';
import '../index.css';
import {Slider} from "@mui/material";
import * as d3 from "d3";
import { useUpdateEffect } from 'ahooks';
import { useInterval } from 'ahooks';
import Solver from "./Solver";
import { log } from 'mathjs';

function Canvas({gamma, betaI, betaE, epsilon, dt, maxT, N, date, trigger, setRunning}) {
    const [img, setImg] = React.useState();
    const [index, setIndex] = React.useState(0);
    const [delay, setDelay] = React.useState(undefined);
    const [line, setLine] = React.useState([]);
    const [cut, setCut] = React.useState(0);

    useInterval(() => {
        setIndex(index + 1);
    }, delay);

    function step(index) {
        if (index === 1) {
            setImg("/sies_" + date + ".gif");
        }
        if (index === cut) {
            d3.select(".break")
                .attr("fill", "none")
                .attr("stroke", "black")
                .attr("stroke-width", 1)
                .attr("stroke-dasharray", "10,10")
                .attr("d", d3.line()
                    .x(function (d) {
                        return x(d[0])
                    })
                    .y(function (d) {
                        return y(d[1])
                    })([[date, 0], [date, N]])
                );
        }
        if (index === line.length) {
            setRunning(0);
            setDelay(undefined);
            setIndex(0);
        }
        if (index !== 0) {
            setImg("/heatmap/sies_" + date + "_" + Math.floor((index - 1) * 60 / line.length) + ".png");
            d3.select(".lineS")
                .attr("fill", "none")
                .attr("stroke", "#66cc33")
                .attr("stroke-width", 4)
                .attr("d", d3.line()
                    .x(function (d) {
                        return x(d[0])
                    })
                    .y(function (d) {
                        return y(d[1][0])
                    })(line.slice(0, index + 1))
                );

            d3.select(".lineE")
                .attr("fill", "none")
                .attr("stroke", "#0099cc")
                .attr("stroke-width", 4)
                .attr("d", d3.line()
                    .x(function (d) {
                        return x(d[0])
                    })
                    .y(function (d) {
                        return y(d[1][1])
                    })(line.slice(0, index + 1))
                );

            d3.select(".lineI")
                .attr("fill", "none")
                .attr("stroke", "#ff6600")
                .attr("stroke-width", 4)
                .attr("d", d3.line()
                    .x(function (d) {
                        return x(d[0])
                    })
                    .y(function (d) {
                        return y(d[1][2])
                    })(line.slice(0, index + 1))
                );
        }
    }

    function clear() {
        d3.select(".break").attr('d', null);
        d3.select(".lineS").attr('d', null);
        d3.select(".lineE").attr('d', null);
        d3.select(".lineI").attr('d', null);
        setImg("/heatmap/sies_20_0.png");
    }

    function preload(date) {
        [...Array(60).keys()].forEach(i => {
            new Image().src = process.env.PUBLIC_URL + "/heatmap/sies_" + date + "_" + i + ".png";
        })
    }

    function render() {
        const LV1 = (t, y) => {
            return [
                -betaE * y[1] * y[0] / N - betaI * y[2] * y[0] / N + gamma * y[2],
                betaE * y[1] * y[0] / N + betaI * y[2] * y[0] / N - epsilon * y[1],
                epsilon * y[1] - gamma * y[2]
            ]
        }
        const LV2 = (t, y) => {
            return [
                -betaE * y[1] * y[0] / N + gamma * y[2],
                betaE * y[1] * y[0] / N - epsilon * y[1],
                epsilon * y[1] - gamma * y[2]
            ]
        }
        const res1 = (date !== 0) ? (new Solver(LV1, [99, 1, 0], 0, date)).euler(date / dt) : {ts: [], ys: []};
        const midY = (date !== 0) ? res1.ys[res1.ys.length-1] : [99, 1, 0];
        const midT = (date !== 0) ? res1.ts[res1.ts.length-1] : 0;
        const res2 = (date !== maxT) ? (new Solver(LV2, midY, midT, maxT)).euler((maxT - date) / dt) : {ts: [], ys: []};
        const res = {ts: res1.ts.concat(res2.ts), ys: res1.ys.concat(res2.ys)};
        preload(date);
        clear();
        setRunning(2);
        setLine(res.ts.map((k, i) => [k, res.ys[i]]));
        setCut(res1.ts.length);
        setDelay(8);
    }


    const lineCanvas = React.useRef();

    const margin = {top: 10, right: 60, bottom: 30, left: 60},
        width = 592 - margin.left - margin.right,
        height = 280 - margin.top - margin.bottom;
    const x = d3.scaleLinear()
        .domain([0, maxT])
        .range([ 0, width ]);
    const y = d3.scaleLinear()
        .domain([0, N])
        .range([ height, 0 ]);

    React.useEffect(() => {
        // console.log('init line canvas')
        const lineD3 = d3.select(lineCanvas.current)
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform",
                "translate(" + margin.left + "," + margin.top + ")");
        lineD3.append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x));
        lineD3.append("g")
            .call(d3.axisLeft(y));

        lineD3.append("path").attr("class", "break");
        lineD3.append("path").attr("class", "lineS");
        lineD3.append("path").attr("class", "lineE");
        lineD3.append("path").attr("class", "lineI");


    }, [])
    React.useEffect(clear,[date]);
    useUpdateEffect(() => {step(index);}, [index]);
    useUpdateEffect(() => {render();}, [trigger]);

    return (
        <div>
            <svg className="bg-blue-100 mb-auto mt-2" ref={lineCanvas}>
                {/*<rect className="fill-transparent stroke-1 stroke-zinc-900" x="400" y="10" width="150" height="100" />*/}
                <circle style={{fill: "#66cc33"}} cx="450" cy="30" r="10" />
                <text x="465" y="35">健康</text>
                <circle style={{fill: "#0099cc"}} cx="450" cy="55" r="10" />
                <text x="465" y="60">潜伏期</text>
                <circle style={{fill: "#ff6600"}} cx="450" cy="80" r="10" />
                <text x="465" y="85">感染</text>
                <text x="20" y="100" writingMode="tb-rl">人数</text>
            </svg>
            <div className="bg-amber-100 mb-auto mt-2 w-full mx-auto">
                <img src={process.env.PUBLIC_URL + img} className="w-3/4 mx-auto" alt="密度图"></img>
            </div>
        </div>

    )
}


function Stimulus1({qid}) {
    const [date, setDate] = React.useState(40);
    const [running, setRunning] = React.useState(0);
    const [trigger, setTrigger] = React.useState(0);
    const maxT = 60;

    const [msg,setMsg] = React.useState({
        content: '',
        questionId: '141',
        nodeId: '01',
        type: 'SELECTION',
    });

    // 更新即向iframe父组件传递msg
    React.useEffect(()=>{
        console.log('usrEffect被触发');
        top.postMessage(msg,'http://121.4.138.132:8888/#/epidemiology')
        // top.postMessage(msg,'http://localhost:8081/#/biologic_question_1')
    },[msg])

    return (
        <div className="bg-gray-300 h-full p-2 flex flex-col">
            <div className="bg-white flex items-center justify-evenly h-16">
                <div className="font-bold text-center w-24">
                    每日全员核酸检测启动时间
                </div>
                <div className="w-80">
                    <Slider
                        aria-label="Small steps"
                        defaultValue={40}
                        getAriaValueText={value => value + "天"}
                        step={5}
                        marks={Array(maxT / 5 + 1).fill(0).map((x, y) => ({
                            value: x + y * 5,
                            label: (x + y * 5) % 10 === 0 ? x + y * 5 + '天' : "",
                        }))}
                        min={0}
                        max={maxT}
                        onChange={(e, v) => {
                            setDate(v);
                            let message = JSON.stringify({
                                position: v
                            })
                            let data
                            if (qid === 2){
                                data = Object.assign({}, msg, { content : message , questionId : '141', nodeId: '03', type: 'ONE_DIMENSIONAL_DRAGGING'})
                            }else if (qid === 3){
                                data = Object.assign({}, msg, { content : message , questionId : '142', nodeId: '03', type: 'ONE_DIMENSIONAL_DRAGGING'})                            
                            }
                            console.log(data);
                            setMsg(data)
                        }}
                        disabled={running !== 0}
                        valueLabelDisplay="auto"
                    />
                </div>
                <button
                    className={"w-12 font-bold rounded " + (running ? "bg-gray-200" : "bg-gradient-to-br from-blue-400 to-blue-200")}
                    disabled={running !== 0}
                    onClick={() => {
                        setRunning(1);
                        setTrigger(trigger+1);
                    }}>
                    {running === 1 ? '加载中' : '运行'}
                </button>
            </div>

            <Canvas gamma={2 / 20} betaI={6 / 20} betaE={3 / 20} epsilon={10 / 20}
                    dt={0.1} maxT={60} N={100} date={date} trigger={trigger} setRunning={setRunning}/>

        </div>
    )
}

export default Stimulus1;
