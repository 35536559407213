import '../index.css';

function Demo1() {
    return (
        <div>
            {/* 题目标题 */}
            <div className="itemTitle">
                模拟区介绍
            </div>


            {/* 问题区域 */}
            <div className='itemStem'>
                <div className='bgContext'>你可以使用右侧模拟区来研究疾病在人群中传播时，开展日常全员核酸检测对传播效果的影响。</div>
                <div className='bgContext'>在该模拟中，人群中的一个个体仅会处于以下三种状态中的一种</div>
            </div>

            <div className="border-2 m-2 mb-4">
                <ul className="list-disc ml-6">
                    <li className="mb-1">
                        <span className="font-bold" style={{color: "#66cc33"}}>健康：</span>
                        处于这种状态的个体，没有患病症状而且没有传染性；可以被传染为潜伏期人群。
                    </li>
                    <li className="mb-1">
                        <span className="font-bold" style={{color: "#0099cc"}}>潜伏期：</span>
                        处于这种状态的个体，虽然没有患病症状，无法被检测到，但是已经染病，有较弱的传染性；经过一段时间后会转为感染人群。
                    </li>
                    <li className="mb-1">
                        <span className="font-bold" style={{color: "#ff6600"}}>感染：</span>
                        处于这种状态的个体，已有患病症状，可以被筛查并隔离，并且传染性较强；病情痊愈后即为健康人群。
                    </li>
                </ul>
            </div>

            <div className='bgContext'>请按以下步骤进行实验操作：</div>
            
            <div className="border-2 m-2">
                <ul className="list-decimal ml-6">
                    <li className="mb-1">
                        移动滑块设置<span className="font-bold">启动每日全员核酸检测的时间</span>。
                    </li>
                    <li>
                        点击“运行”按钮来观察健康、潜伏期、感染这三种状态的人口所占百分比，以及感染者分布热力图随时间的变化。
                    </li>
                </ul>
            </div>


        </div>
    )
}

export default Demo1;
