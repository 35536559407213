import React from 'react';
import './index.css';
import './styles/delivery.css'
import Question1 from "./item/Question1";
import Stimulus1 from "./item/Stimulus1";
import Background from "./item/Background";
import Demo1 from "./item/Demo1";
import Question3 from "./item/Question3";
import Stimulus2 from "./item/Stimulus2";
import Question4 from "./item/Question4";
import Question5 from "./item/Question5";
import Question2 from "./item/Question2";
import KeepAlive from 'react-activation';
import { Button } from 'element-react';


function Delivery({ time, resp, setPage, setTime, setResp }) {
    const [qid, setQid] = React.useState(5);

    
    function changeContent(dest) {
        setQid(dest);
    }

    function my_print(){
        console.log("打印测试");
    }

    function jiaojuan(){
        // top.postMessage('haha','http://121.4.138.132:8888/#/epidemiology');
        top.postMessage('haha','locathost:8080//#/epidemiology');
    }

    React.useEffect(()=>{
        window.addEventListener('message',(msg)=>{
            console.log("接收到数据了！");
            // if(msg.data === 2 || msg.data === 3 || msg.data ===4 || msg.data ===5 || msg.data == 6){
            if(msg.data >= 2){
                setQid(msg.data);
            }
        })
    },[])

    const contents = {
        0: [[<Background />, { width: '1047px', height: '690px' ,padding: '10px',border:'solid 10px #5a92c2'}]],
        1: [[<Demo1 />, { width: '440px', height: '690px' ,padding: '10px',border:'solid 10px #5a92c2'}], [<Stimulus1 />, { width: '608px', height: '690px' }]],
        2: [[<KeepAlive cacheKey="Question1"><Question1 /></KeepAlive>, { width: '592px', height: '690px' ,padding: '10px',border:'solid 10px #fef3c7'}], [<Stimulus1 qid={qid} />, { width: '608px', height: '690px' }]],
        3: [[<KeepAlive cacheKey="Question2"><Question2 /></KeepAlive>, { width: '592px', height: '690px' ,padding: '10px',border:'solid 10px #fef3c7'}], [<Stimulus1 qid={qid} />, { width: '608px', height: '690px' }]],
        4: [[<KeepAlive cacheKey="Question3"><Question3 /></KeepAlive>, { width: '1200px', height: '800px' ,padding: '10px',border:'solid 10px #fef3c7',overflowY: 'auto'}]],
        // 5: [[<Demo2 />, { width: '399px', height: '706px'}], [<Stimulus2 />, { 500px: '608px', height: '706px' }]],
        5: [[<KeepAlive cacheKey="Question4"><Question4 /></KeepAlive>, { width: '592px', height: '690px' ,padding: '10px',border:'solid 10px #fef3c7'}], [<Stimulus2 qid={qid} />, { width: '608px', height: '690px' }]],
        6: [[<KeepAlive cacheKey="Question5"><Question5 /></KeepAlive>, { width: '592px', height: '690px' ,padding: '10px',border:'solid 10px #fef3c7'}], [<Stimulus2 qid={qid} />, { width: '608px', height: '690px' }]],
    }

    return (
        <div className='deliveryContainer'>

            <div className="mx-auto flex justify-center">
                {contents[qid].map((item, index) => (
                    <div style={item[1]} key={index}>
                        {item[0]}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Delivery;
