import React from 'react';
import '../index.css';
import '../styles/question1.css'

function Question1() {
    const [nowText, setNowText] = React.useState("");

    // 需要传递的对象
    const [msg,setMsg] = React.useState({
        content: '',
        questionId: '141',
        nodeId: '01',
        type: 'SELECTION',
    });

    // 根据选择的不同改变msg的值
    function changeSelection(e) {
        let message = JSON.stringify({
            choice: e.target.value
        })
        let data = Object.assign({}, msg, { content : message ,nodeId: '01' ,type : 'SELECTION'})
        setMsg(data)
    }

    // 根据输入内容的不同改变msg的值
    function changeTextArea(e) {
        let message = JSON.stringify({
            beforeText:  nowText,
            afterText: e.target.value,
        })
        setNowText(e.target.value)
        let data = Object.assign({}, msg, { content : message ,nodeId: '02' ,type : 'TEXT_BOX_INPUT'})
        setMsg(data)
    }


    // 更新即向iframe父组件传递msg
    React.useEffect(()=>{
        console.log('usrEffect被触发');
        top.postMessage(msg,'http://121.4.138.132:8888/#/epidemiology')
        // top.postMessage(msg,'http://localhost:8081/#/biologic_question_1')
    },[msg])

    return (
        <div>
            {/* 题目标题 */}
            <div className="itemTitle">
                <b>第4题 第1问</b>
            </div>

            {/* 问题区域 */}
            <div className='itemStem'>
                <div className='bgContext'>根据以下信息运行模拟，收集数据。点击一个选项并回答问题。</div>
                <div className='bgContext'>研究人员想要确定核酸检测如何影响该疾病的患病人数，以了解城市是否能够承受医疗压力。</div>
                <div className='bgContext'>核酸检测启动后，感染者个体数目如何变化？</div>
            </div>

            <div>
                <div className="inputItem">
                    <input type="radio" id="q11" name="q1" value="数目增加" onChange={changeSelection} />
                    <label htmlFor="q11">数目增加</label>
                </div>
                <div className="inputItem">
                    <input type="radio" id="q12" name="q1" value="数目降低" onChange={changeSelection} />
                    <label htmlFor="q12">数目降低</label>
                </div>
                <div className="inputItem">
                    <input type="radio" id="q13" name="q1" value="数目先增加后降低"onChange={changeSelection} />
                    <label htmlFor="q13">数目先增加后降低</label>
                </div>
                <div className="inputItem">
                    <input type="radio" id="q14" name="q1" value="数目保持不变" onChange={changeSelection} />
                    <label htmlFor="q14">数目保持不变</label>
                </div>
            </div>

            <div className='bgContext'>解释在疾病传播的过程中，核酸检测为什么会产生这样的效果。</div>
            
            <div className="mt-4">
                <textarea id="reason1" className="textarea textarea-bordered w-full" placeholder="请输入您的理由" onBlur={(e)=> changeTextArea(e)}></textarea>
            </div>


        </div>
    )
}

export default Question1;