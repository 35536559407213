import React from 'react';
import '../index.css';
import '../styles/question1.css'

function Question1() {
    // 选择题的value
    const [radioValue, setRadioValue] = React.useState(); 
    const [nowText, setNowText] = React.useState("");

    // 需要传递的对象
    const [msg,setMsg] = React.useState({
        content: '',
        questionId: '142',
        nodeId: '01',
        type: 'SELECTION',
    });

    // 根据选择的不同改变msg的值
    function changeSelection(e) {
        let message = JSON.stringify({
            choice: e.target.value
        })
        let data = Object.assign({}, msg, { content : message ,nodeId: '01' ,type : 'SELECTION'})
        setMsg(data)
    }

    // 根据输入内容的不同改变msg的值
    function changeTextArea(e) {
        let message = JSON.stringify({
            beforeText:  nowText,
            afterText: e.target.value,
        })
        setNowText(e.target.value)
        let data = Object.assign({}, msg, { content : message ,nodeId: '02' ,type : 'TEXT_BOX_INPUT'})
        setMsg(data)
    }

    // 更新即向iframe父组件传递msg
    React.useEffect(()=>{
        top.postMessage(msg,'http://121.4.138.132:8888/#/epidemiology')
        // top.postMessage(msg,'http://localhost:8081/#/biologic_question_3')
    },[msg])

    return (
        <div>
            {/* 题目标题 */}
            <div className="itemTitle">
                <b>第4题 第2问</b>
            </div>

            {/* 问题区域 */}
            <div className='itemStem'>
                <div className='bgContext'>根据以下信息运行模拟，收集数据。点击一个选项并回答问题。</div>
                <div className='bgContext'>下图所示为另外一种疾病对人群的影响，假设在40天时启动核酸检测。</div>
                <div className='bgContext'>核酸检测启动后，感染者个体数目如何变化？</div>
            </div>

            <div className="p-2">
                <p className="font-bold text-center">另一种疾病的传播过程</p>
                <img id='img1' src={process.env.PUBLIC_URL + "/latency.png"} alt="latency"/>

            </div>

            {/* 选择项 */}
            <div>
                <div className="inputItem">
                    <input type="radio" id="q11" name="q1" value="疾病的潜伏期更长" onChange={changeSelection}/>
                    <label htmlFor="q11">疾病的潜伏期更长</label>
                </div>
                <div className="inputItem">
                    <input type="radio" id="q12" name="q1" value="疾病的潜伏期更短" onChange={changeSelection}/>
                    <label htmlFor="q12">疾病的潜伏期更短</label>
                </div>
            </div>

            <div className='bgContext'>相较于模拟展示的疾病，上图所示疾病的潜伏期更长还是更短？</div>

            <div className='bgContext'>解释模拟结果如何支持你的选择。</div>

            <div className="mt-4">
                <textarea id="reason1" className="textarea textarea-bordered w-full" placeholder="请输入您的理由" onBlur={(e)=> changeTextArea(e)}></textarea>
            </div>


        </div>
    )
}

export default Question1;