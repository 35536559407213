import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AliveScope } from 'react-activation'
import 'element-theme-default';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AliveScope>
    <App />
  </AliveScope>
  // <React.StrictMode>
  // </React.StrictMode>
);
