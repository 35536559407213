import './index.css';

function processTime(time) {
    function formatTime(ms) {
        const second = Math.floor((ms % (1000 * 60)) / 1000);
        const minute = Math.floor(ms / (1000 * 60));
        if (minute === 0) {
            return second + "秒";
        } else {
            return minute + "分" + second + "秒";
        }
    }

    let nowQid = time[0], nowTime = time[1];
    const finalTime = time[time.length - 1];
    let qidTime = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0
    };
    for (let i = 2; i < (time.length - 1); i += 2) {
        qidTime[nowQid] += time[i] - nowTime;
        nowTime = time[i];
        nowQid = time[i + 1];
    }
    qidTime[nowQid] += finalTime - nowTime;

    let sumTime = 0;
    for (let i = 0; i <= 6; i++) {
        sumTime += qidTime[i];
        qidTime[i] = formatTime(qidTime[i]);
    }
    sumTime = formatTime(sumTime);
    return [qidTime, sumTime];
}

function Report({ time, resp }) {
    const [qidTime, sumTime] = processTime(time);
    return (
        <div className='bg-base-100 p-6 flex flex-col'>
                <div className="stats shadow mx-auto mb-6">
                    <div className="stat">
                        <div className="stat-title">答题总用时</div>
                        <div className="stat-value">{sumTime}</div>
                        {/* <div className="stat-desc">21% more than last month</div> */}
                    </div>
                    <div className="stat">
                        <div className="stat-title">第一题用时</div>
                        <div className="stat-value">{qidTime[2]}</div>
                    </div>
                    <div className="stat">
                        <div className="stat-title">第二题用时</div>
                        <div className="stat-value">{qidTime[3]}</div>
                    </div>
                    <div className="stat">
                        <div className="stat-title">第三题用时</div>
                        <div className="stat-value">{qidTime[4]}</div>
                    </div>
                    <div className="stat">
                        <div className="stat-title">第四题用时</div>
                        <div className="stat-value">{qidTime[5]}</div>
                    </div>
                    <div className="stat">
                        <div className="stat-title">第五题用时</div>
                        <div className="stat-value">{qidTime[6]}</div>
                    </div>
                </div>

        </div>
    );
}

export default Report;
